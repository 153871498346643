import classnames from 'classnames/bind';
import { Theme, themeToString } from '../../models/Models';

import Banner from '../../assets/bannerImages/banner-main-page.svg';
import styles from './ErrorBlock.module.scss';
import Text from '@/components/text/Text';

interface ErrorBlock {
  theme?: Theme;
}

const ErrorBlock = (props: ErrorBlock) => {
  const { theme = Theme.Light } = props;
  const componentClass = 'error-block';
  const cx = classnames.bind(styles);

  return (
    <section
      className={cx(componentClass, {
        [`${componentClass}--${themeToString(theme)}`]: theme !== null,
      })}
    >
      <div>
        <Text theme={theme} type="h1" text="Oj beklager!" />
        <Text
          theme={theme}
          type="p"
          text="Noe her gikk ikke helt etter planen"
        />
      </div>
      <Banner className={cx(`${componentClass}__image`)} alt="banner" />
    </section>
  );
};

export default ErrorBlock;
